<template>
    <div class="Calibration full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <navigation-left-small noBackButton/>
            <div class="content-area pa-5 flex-grow-1 bg-white">
                <v-row class="d-flex">
                    <v-col cols="6" class="pa-0 pr-4">
                        <v-row class="d-flex align-center">
                            <h2 class="pa-3 pb-2">{{ $t('adjCal.height') }}</h2>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('adjCal.basket') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <p class="readOnly">{{ calibration.height.basket }}</p>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('adjCal.lastCalibration') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <p class="readOnly container">
                                    {{ calibration.height.dateTime }}
                                    <span class="bullet" :class="{'successful': calibration.height.status}"></span>
                                </p>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('adjCal.nextCalibrationDate') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <p class="readOnly" :class="{'overdue': calibration.height.overdue}">{{ calibration.height.nextCalibrationDate }}</p>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <router-link to="/calibration-height" class="flex-grow-1 mr-6" :event="checkUserRights('calibration') ? 'click' : ''">
                                <div class="ma-3 content-btn" :class="{'disabled': !checkUserRights('calibration')}">{{ $t('adjCal.calibrateHeight') }}</div>
                            </router-link>
                        </v-row>
                    </v-col>
                    <v-col cols="6" class="pa-0 pl-4">
                        <v-row class="d-flex align-center">
                            <h2 class="pa-3 pb-2">{{ $t('adjCal.temperature') }}</h2>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('adjCal.basket') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <p class="readOnly">{{ calibration.temperature.basket }}</p>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('adjCal.lastCalibration') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <p class="readOnly container">
                                    {{ calibration.temperature.dateTime }}
                                    <span class="bullet" :class="{'successful': calibration.temperature.status}"></span>
                                </p>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('adjCal.nextCalibrationDate') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <p class="readOnly" :class="{'overdue': calibration.temperature.overdue}">{{ calibration.temperature.nextCalibrationDate }}</p>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <router-link to="/calibration-temp" class="flex-grow-1 mr-6" :event="checkUserRights('calibration') ? 'click' : ''">
                                <div class="ma-3 content-btn" :class="{'disabled': !checkUserRights('calibration')}">{{ $t('adjCal.calibrateTemperature') }}</div>
                            </router-link>
                        </v-row>
                    </v-col>
                    <v-col cols="6" class="pa-0 pr-4">
                        <v-row class="d-flex align-center ">
                            <h2 class="ma-3 mt-8 pt-8 mb-2 border-top">{{ $t('adjCal.basketPosition') }}</h2>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('adjCal.basket') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <p class="readOnly">{{ calibration.basketPosition.tubeCount }} {{$t('general.tubes')}}</p>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('adjCal.lastCalibration') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <p class="readOnly container">
                                    {{ calibration.basketPosition.dateTime }}
                                    <span class="bullet" :class="{'successful': calibration.basketPosition.status}"></span>
                                </p>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('adjCal.nextCalibrationDate') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <p class="readOnly" :class="{'overdue': calibration.basketPosition.overdue}">{{ calibration.basketPosition.nextCalibrationDate }}</p>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <router-link to="/calibration-position" class="flex-grow-1 mr-6" :event="checkUserRights('calibration') ? 'click' : ''">
                                <div class="ma-3 content-btn" :class="{'disabled': !checkUserRights('calibration')}">{{ $t('adjCal.calibrateBasketPosition') }}</div>
                            </router-link>
                        </v-row>
                    </v-col>
                    <v-col cols="6" class="pa-0 pl-4">
                        <v-row class="d-flex align-center ">
                            <h2 class="ma-3 mt-8 pt-8 mb-2 border-top">{{ $t('adjCal.frequency') }}</h2>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('adjCal.lastCalibration') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <p class="readOnly container">
                                    {{ calibration.frequency.dateTime }}
                                    <span class="bullet" :class="{'successful': calibration.frequency.status}"></span>
                                </p>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('adjCal.nextCalibrationDate') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <p class="readOnly" :class="{'overdue': calibration.frequency.overdue}">{{ calibration.frequency.nextCalibrationDate }}</p>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <router-link to="/calibration-freq" class="flex-grow-1 mr-6" :event="checkUserRights('calibration') ? 'click' : ''">
                                <div class="ma-3 content-btn" :class="{'disabled': !checkUserRights('calibration')}">{{ $t('adjCal.calibrateFrequency') }}</div>
                            </router-link>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button buttonFunction="back" @footerButtonClick="goToAdjCal"></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button button-function="reports" @footerButtonClick="goToCalReports"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import NavigationLeftSmall from '@/components/NavigationLeftSmall.vue'
import FooterButton from '@/components/FooterButton.vue'
import {mapState} from "vuex";
import mixins from "@/mixins/mixins";

export default {
    name: 'Calibration',
    components: {
        NavigationLeftSmall,
        FooterButton,
    },
    props: {
    },
    data () {
        return{
        }
    },
    computed: {
        ...mapState([
            'calibration',
            'systemsettings',
            'activeStationId',
        ]),
    },
    methods:{
        goToAdjCal(){
            this.$router.push('adj-cal');
        },
        goToCalReports(){
            this.$router.push('calibration-reports');
        },
    },
    mixins: [mixins],
    created() {
        this.$store.dispatch('getAxiosSetter', ['/admin/share/pathlist', 'pathlist']);
        this.$store.dispatch('getAxiosSetter', ['/disi/calibration/settings', 'calSettings']);
        this.$store.dispatch('postAxiosSetter', ['/disi/calibration/calibrate/latest', {id: this.activeStationId}, 'calibration']);
        this.$store.commit('resetCalibrationFrequency');
        this.$store.commit('resetProgress');

        this.$watch('$store.state.activeStationId', function() {
            this.$store.dispatch('postAxiosSetter', ['/disi/calibration/calibrate/latest', {id: this.activeStationId}, 'calibration']);
        });
    }
}
</script>
<style scoped lang="scss">
.content-btn{
    max-height: 52px;
}
h2{
    width: 100%;
    font-size: 1rem;
}
.border-top{
    border-top: 1px solid $disiBluegreyDark;
}
.overdue{
    position: relative;
    &::before{
        content: "";
        position: absolute;
        right: 12px;
        width: 28px;
        height: 28px;
        background-color: $disiRed;
        background-image: url("../assets/warn_weiss.svg");
        background-size: 6px;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.container{
    position: relative;
}
.bullet{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 12px;
    &::before{
        content: "";
        display: inline-block;
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: $disiRed;
    }
    &.successful::before{
        background-color: $disiIrisblue;
    }
}
</style>